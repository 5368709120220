<template>
    <body>
        <div class="login">
            <div class="l-block toggled" id="l-login">
                <div class="lb-header">
                    <i class="zmdi zmdi-account-circle"></i>
                    Hi there! Please Sign in here
                </div>

                <div class="lb-body">
                    <form @submit.prevent="login">
                        <div class="form-group">
                            <label>Email Address</label>
                            <input type="email" class="form-control" v-model="user.email">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" v-model="user.password">
                        </div>
                        <button type="submit" class="btn lb-btn">Sign in</button>
                    </form>
                    <div class="m-t-20">
                        <!-- <router-link to="/forgot-password">
                            Forgot password?
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </body>

</template>

<script>
    export default {
        data(){
            return {
                user: {
                    email: "",
                    password: "",
                }
            }
        },
        methods: {
            login(){
                this.$store.dispatch('login', this.user).then(response => {
                    if(response.data.code === 200){
                        this.$notify.success(response.data.reason)
                        if (response.data.status == 1) {
                            this.$router.push('/')
                        } else if (response.data.status == 0) {
                            this.$router.push('/verify')                            
                        }
                    } else {
                        this.$notify.error(response.data.reason)
                    }
                })
            },
        }
    }
</script>